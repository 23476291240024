import styled from '@emotion/styled'
import { Heading } from 'theme-ui'

export const Title = styled(Heading)`
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  text-align: center;

  &:after {
    content: '';
    width: 30%;
    height: 1px;
    background: black;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`
