/** @jsx jsx */

import { Container, Flex, jsx } from 'theme-ui'
import { Title } from './styles'

const Share = () => {
  return (
    <Container
      variant="fullWidth"
      sx={{
        width: ['100%', '80%'],
        paddingTop: 'calc(var(--nav-bar-height) + 4rem)'
      }}
    >
      <Flex sx={{ justifyContent: 'center' }}>
        <Title
          as="h2"
          sx={{
            fontSize: [6, 8],
            pb: [3, 4],
            mb: [4, 6]
          }}
        >
          REPUS DASHBOARD
        </Title>
      </Flex>
      <div id="talkable-offer" sx={{ mb: '50px' }}></div>
    </Container>
  )
}

export default Share
